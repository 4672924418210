<template>
  <div>
    <div v-if="documentEmpty">
      <slot name="noDocuments">
        <div>
          <h6>
            <em>Non sono presenti documenti allegati</em>
          </h6>
        </div>
      </slot>
    </div>
    <document-item
      v-for="doc in documents"
      read-only
      :key="doc.documentId"
      :current-doc="doc"
      :entity-id="entityId"
      :entity-class="entityClass"
      :slim="slim"
    />
  </div>
</template>

<script>
const DocumentItem = () => import('@/components/documents/DocumentItem.vue');

export default {
  name: 'DocumentList',
  props: {
    entityClass: String,
    entityId: String,
    documents: Array,
    slim: Boolean,
  },
  components: { DocumentItem },
  computed: {
    documentEmpty() {
      return this.documents.length === 0;
    },
  },
};
</script>

<style scoped>

</style>
